import * as React from 'react'
import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout pageTitle="Helsingin Tietotekniikkapalvelut">
      <p>Tarjoamme konstulttipalveluja pk-yritykselle pääkaupungiseudulla.</p>
    </Layout>
  )
}
export default IndexPage